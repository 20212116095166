import React from 'react'

const ChatItem = ({ messages,myEmployeeId }) => {
  console.log("myEmployeeId: ", myEmployeeId);
  
    return (
      <div className="chat-container">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`chat-message ${msg.employeeId === myEmployeeId ? "my-message" : "other-message"}`}
          >
            <div className="chat-message-content">
              {msg.employeeId !== myEmployeeId && <strong>{msg.employeeName} </strong>}
              <span>{msg.message}</span>
            </div>
          </div>
        ))}
      </div>
    );
  };

export default ChatItem
