import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Flex,
  Modal,
  Select,
  Space,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoCheckmarkOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router";
import { deliveryManager, linkEmployees, report } from "../api";
import TableComponent from "../utils/TableComponent";
import { employeeId } from "../utils/userDetails";
import ApproveForm from "./Form/ApproveForm";
import ProjectViewEmp from "./ProjectViewEmp";
import { useLocation } from "react-router-dom";

const DeliveryManager = () => {
  const navigate = useNavigate();
  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthIndex = new Date().getMonth();
  const currentDate = moment();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const month = location?.state?.month;
  const year = location?.state?.year;
  const name = location?.state?.name;
  const check = location?.state?.check;
  const [currentMonth, setCurrentMonth] = useState(
    month || currentDate.month() + 1
  );
  const [currentYear, setCurrentYear] = useState(year || currentDate.year());
  const [availableMonths, setAvailableMonths] = useState(
    currentYear < currentDate.year()
      ? monthArray
      : monthArray.slice(0, currentMonthIndex + 1)
  );
  const [project, setProject] = useState(null);
  const [employeeArray, setEmployeeArray] = useState([]);
  const [projectArray, setProjectArray] = useState([]);
  const [projectTypeArray, setProjectTypeArray] = useState([
    { label: "All", value: "all" },
    { label: "Internal", value: "isInternal" },
    { label: "Client", value: "isClient" },
  ]);
  const [projectType, setProjectType] = useState("all");
  const [projectData, setProjectData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [approveTableOpen, setApproveTableOpen] = useState(check || false);

  const handleMonthChange = (value) => {
    setCurrentMonth(value);
  };

  const handleYearChange = (value) => {
    setCurrentYear(value);

    if (value < currentDate.year()) {
      setAvailableMonths(monthArray);
      setCurrentMonth(currentMonthIndex);
    } else if (value === currentDate.year()) {
      setAvailableMonths(monthArray.slice(0, currentMonthIndex + 1));
      setCurrentMonth(currentMonthIndex+1);
    } else {
      setAvailableMonths([]);
    }
  };

  const handleProjectChange = (value) => {
    setProject(value);
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      total: projectData.length,
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleApprovalStatusClick = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
    setIsModalOpen((prev) => !prev);
  };

  const columns = [
    {
      title: "Employee",
      dataIndex: "employeeName",
      key: "employeeName",
      fixed: "left",
      width: "20%",
      className: "custom-table-row",
    },
    {
      title: "Billable",
      dataIndex: "isBillable",
      key: "isBillable",
      width: "5%",
      className: "custom-table-row",
      render: (text) => {
        return !text ? (
          <RxCross1
            style={{ margin: "0 0 0 1rem", color: "df0b2e", fontSize: "16px" }}
          />
        ) : (
          <IoCheckmarkOutline
            style={{ margin: "0 0 0 1rem", color: "green", fontSize: "18px" }}
          />
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      fixed: "left",
      width: "25%",
      className: "custom-table-row",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      fixed: "left",
      width: "25%",
      className: "custom-table-row",
    },
    {
      title: "Action",
      dataIndex: "Add Access",
      key: "Add Access",
      fixed: "right",
      width: "25%",
      className: "custom-table-row",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Button
              type="primary"
              size="small"
              disabled={!!(record.statusId !== 2)}
              onClick={() => handleApprovalStatusClick(record)}
              style={{
                backgroundColor:
                  record.statusId === 3
                    ? "Green"
                    : record.statusId === 4
                    ? "#df0b2e"
                    : "",
                color:
                  record.statusId === 3 || record.statusId === 4 ? "white" : "",
              }}
            >
              {record.statusId === 3
                ? "Approved"
                : record.statusId === 4
                ? "Rejected"
                : "Pending from employee"}
            </Button>
            <Tooltip placement="top" title="View" color="8e8e8e" arrow={false}>
              <a
                onClick={() => {
                  navigate("/delivery-manager-view", {
                    state: {
                      name: project,
                      month: currentMonth,
                      year: currentYear,
                      employeeId: record.employeeNo,
                      projectName: record.projectName,
                      employeeName: record.employeeName,
                      approveTableOpen: approveTableOpen,
                    },
                  });
                }}
                style={{ fontSize: "16px", textDecoration: "none" }}
              >
                <EyeOutlined />
              </a>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const getAllEmployees = async () => {
    try {
      const response = await linkEmployees.getAllEmployees();
      const newResponse = response?.employees.map((employee) => {
        return {
          value: employee.employeeNo,
          label: employee.employeeName,
        };
      });
      setEmployeeArray(newResponse);
    } catch (error) {
      console.log("get all employee error", error);
    }
  };

  const getAllProjects = async () => {
    try {
      const response = await deliveryManager.getApprovalProject({
        approverId: employeeId,
        month: currentMonth,
        year: currentYear,
        projectType: projectType,
      });
      const newResponse =
        response?.data &&
        response.data.map((project) => {
          return {
            label: project?.projectName,
            value: project?.projectId,
            isInternal: project?.isInternal,
          };
        });
      setProjectArray(newResponse || []);
      setProject(response?.data[0].projectId);
    } catch (error) {
      console.log(" error", error);
    }
  };

  const projectView = async (newProject) => {
    try {
      setLoading(true);
      const response = await deliveryManager.getEmployeeByApprover({
        approverId: employeeId,
        month: currentMonth,
        year: currentYear,
        projectId: newProject,
      });
      const projectName = projectArray.filter(
        (item) => item.value === newProject
      )[0]?.label;
      const projectD = response?.data[0]?.employees?.map((data) => {
        const employeeName = employeeArray.filter(
          (item) => item.value === data.employeeNo
        )[0].label;
        return {
          employeeName: employeeName,
          employeeNo: data.employeeNo,
          isBillable: data.isBillable,
          statusId: data.statusId,
          startDate: data.startDate,
          endDate: data.endDate,
          projectName,
        };
      });
      setProjectData(projectD);
    } catch (error) {
      console.log("project view error", error);
      setProjectData([]);
      message.error(error?.response?.data?.error || "An Error Occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleTableShown = () => {
    setApproveTableOpen(!approveTableOpen);
  };

  useEffect(() => {
    getAllEmployees();
    return () => {
      setEmployeeArray([]);
    };
  }, []);

  useEffect(() => {
    getAllProjects();
    return () => {
      setProjectArray([]);
      setProject(null);
    };
  }, [currentMonth, currentYear, projectType]);

  useEffect(() => {
    if (!isModalVisible && currentYear && project != null) {
      setProjectData([]);
      if (employeeArray.length > 0) {
        projectView(project);
      } else {
        // Clear projectData when no employees are available
        setProjectData([]);
      }
    } else {
      setProjectData([]);
    }
  }, [currentMonth, currentYear, project, employeeArray, isModalVisible]);

  const handleExport = async (newProject) => {
    try {
      const res = await report.generateReport({
        projectId: newProject,
        month: currentMonth,
        year: currentYear,
      });

      if (res.msg === "Reports generated successfully") {
        const resp = await report.getReport({
          projectId: newProject,
          month: currentMonth,
          year: currentYear,
        });

        const base64String = resp.file;
        const project = projectArray.find((p) => p.value === newProject);
        const projectName = project
          ? project.label.replace(/\s+/g, "_")
          : "Unknown_Project";
        const month = monthArray[currentMonth - 1];
        const filename = `${projectName}_Timesheet_${month}_${currentYear}.xlsx`;

        // Create an element and set its attributes
        const link = document.createElement("a");
        link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
        link.download = filename;
        link.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectTypeChange = (value) => {
    setProjectType(value);
  };

  return (
    <Flex vertical gap={"2rem"} style={{ padding: 24, height: "93vh" }}>
      <Flex justify="space-between" align="center" gap="2rem" wrap="wrap">
        <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          <div>
            <div className="selectDropDown">Year</div>
            <Select
              placeholder="Select Year"
              defaultValue={currentYear}
              onChange={handleYearChange}
              size="large"
              style={{ width: "100px", textAlign: "left" }}
            >
              {Array.from(
                { length: 5 },
                (_, i) => currentDate.year() - 4 + i
              ).map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="selectDropDown">Month</div>
            <Select
              placeholder="Select Month"
              value={currentMonth || month}
              onChange={handleMonthChange}
              size="large"
              style={{ width: "120px", textAlign: "left" }}
            >
              {availableMonths.map((month, index) => (
                <Select.Option key={index} value={index + 1}>
                  {month}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="selectDropDown">Project Type</div>
            <Select
              placeholder="Select Project Type"
              value={projectType}
              onChange={handleProjectTypeChange}
              size="large"
              style={{ width: "120px", textAlign: "left" }}
              options={projectTypeArray}
              filterOption={filterOption}
              optionFilterProp="children"
              showSearch
            />
          </div>
          <div>
            <div className="selectDropDown">Project</div>
            <Select
              placeholder="Select Project"
              value={projectId ? projectId : project}
              onChange={handleProjectChange}
              size="large"
              style={{ width: "250px", textAlign: "left" }}
              options={projectArray}
              notFoundContent="No project found for selected month,year"
              filterOption={filterOption}
              optionFilterProp="children"
              showSearch
            />
          </div>
        </div>
        <Button
          size="large"
          type="primary"
          icon={<DownloadOutlined style={{ fontSize: "18px" }} />}
          style={{ marginTop: "2rem" }}
          onClick={() => handleExport(project)}
        >
          Export Timesheet
        </Button>
      </Flex>
      <Flex justify="flex-start">
        <Checkbox
          onChange={handleTableShown}
          checked={approveTableOpen}
          style={{ color: "#4097ff", marginTop: "8px" }}
        >
          View Employee Description
        </Checkbox>
      </Flex>
      {approveTableOpen ? (
        <TableComponent
          loading={loading}
          rows={project ? projectData : []}
          columns={columns}
          onChange={handleTableChange}
          locale={{ emptyText: "No data available for given input" }}
        />
      ) : (
        <ProjectViewEmp
          currentYear={currentYear}
          currentMonth={currentMonth}
          project={projectId !== undefined ? projectId : project}
        />
      )}
      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
            Approval Status
          </div>
        }
        visible={isModalVisible}
        footer={null}
        width={550}
        onCancel={() => setIsModalVisible(false)}
      >
        <ApproveForm
          hide={setIsModalVisible}
          data={selectedRecord}
          open={isModalOpen}
          projectId={project}
          month={currentMonth}
          year={currentYear}
        />
      </Modal>
    </Flex>
  );
};

export default DeliveryManager;
