import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Form,
  Radio,
  Select,
  Typography,
  notification,
  Spin,
  Input,
  Table,
  Row,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../../Styles/Form.css";
import { Project, linkEmployees } from "../../api";
import dayjs from "dayjs";

const AddNewTimeSheetForm = ({
  hide,
  linkedEmp,
  Open,
  selectedNewProject = null,
  isEmployeeLinked,
  setIsEmployeeLinked,
  totalEmployees,
  setTotalEmployees
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [project, setProject] = useState([]);
  const [internalProject, setInternalProject] = useState([]);
  const [deliveryManager, setDeliveryManager] = useState([]);
  const [productManager, setProductManager] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employeeLinkedProject, setEmployeeLinkedProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { Text } = Typography;
  const radioOptions = [
    { label: "Client Project", value: "Project" },
    { label: "Internal Project", value: "Internal Project" },
  ];
  const [value, setValue] = useState("Project");
  const [formLoading, setFormLoading] = useState(false);
  const initialEmployeeDept = {
    1: "5G",
    2: "AI/ML",
    3: "Application",
    4: "Business Analyst",
    5: "Cloud",
    6: "Embedded",
    7: "GNSS",
    8: "Human Resources",
    9: "Power Platform",
    10: "Quality Assurance",
    11: "Talent Acquisition",
    12: "UI/UX",
  };
  const [employeeDept, setEmployeeDept] = useState(initialEmployeeDept);
  const [allocatedHoursMap, setAllocatedHoursMap] = useState({});

  useEffect(() => {
    if (!selectedNewProject?.departments) return;

    const updatedEmployeeDept = Object.fromEntries(
      Object.entries(initialEmployeeDept).filter(([key, value]) =>
        selectedNewProject.departments.some((dept) => dept.department === value)
      )
    );

    setEmployeeDept(updatedEmployeeDept);
  }, [selectedNewProject]);

  useEffect(() => {
    if (employeeLinkedProject.length > 0) {
      const project = employeeLinkedProject.find(
        (proj) => proj.projectId === selectedProject
      );

      if (!project?.departments) return;

      const updatedEmployeeDept = Object.fromEntries(
        Object.entries(initialEmployeeDept).filter(([key, value]) =>
          project.departments.some((dept) => dept.department === value)
        )
      );

      setEmployeeDept(updatedEmployeeDept);
    }
  }, [selectedProject]);

  const departmentOptions = Object.entries(employeeDept).map(
    ([key, value]) => ({
      value: value,
      label: value,
    })
  );

  useEffect(() => {
    getAllEmployees();
    getAllProjects();
  }, []);

  useEffect(() => {
    if (Open) {
      getLinkedEmployeeTable();
    }
  }, [Open]);

  const getAllEmployees = async () => {
    try {
      const response = await linkEmployees.getAllEmployees();
      const newResponse = response.employees.map((employee) => {
        return {
          value: employee.employeeNo,
          label: `${employee.employeeName}  (${employee.employeeNo})`,
        };
      });
      setEmployees(newResponse);
      setDeliveryManager(newResponse);
      setProductManager(newResponse);
    } catch (error) {
      console.log("get all employee error", error);
    }
  };

  const getAllProjects = async () => {
    try {
      const response = await Project.getProjects();
      setProject(
        response.projects.map((projects) => {
          return {
            label: projects.projectName,
            value: projects.projectId,
            startDate: projects.startDate,
          };
        })
      );
      setInternalProject(
        response.internalProjects.map((projects) => {
          return {
            label: projects.projectName,
            value: projects.projectId,
            startDate: projects.startDate,
          };
        })
      );
    } catch (error) {
      console.log("get all employee error", error);
    }
  };

  const getLinkedEmployeeTable = async () => {
    try {
      setFormLoading(true);
      const response = await linkEmployees.getLinkedEmployee();
      if (response && Array.isArray(response)) {
        // console.log("response: ", response);
        setEmployeeLinkedProject(response);
      }
      setFormLoading(false);
    } catch (error) {
      console.log("linked Employee Error", error);
    }
  };

  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };

  const onFinish = async (values) => {
    const deliveryManagerId = values.deliveryManagerId;
    if (typeof deliveryManagerId === "object") {
      values.deliveryManagerId = deliveryManagerId.value;
    }
    const productManagerId = values.productManagerId;
    if (typeof productManagerId === "object") {
      values.productManagerId = productManagerId.value;
    }

    const formattedValues = {
      ...values,
      projectId: values.projectId.value,
      employees: values.employees.map((employee) => ({
        employeeNo: employee.employeeNo.value,
        isBillable: employee.isBillable,
        startDate: employee.startDate.format("YYYY-MM-DD"),
        endDate: employee.endDate
          ? employee.endDate.format("YYYY-MM-DD")
          : null,
        isActive: "Active" in employee ? employee.Active : true,
        department: employee.teamId,
        allocatedHours: employee.estimatedHours,
      })),
      isInternalProject: value === "Project" ? false : true,
    };

    try {      
      setLoading(true);
      const resp = await linkEmployees.addTimesheet(formattedValues);
      if (resp.message == "Start & End Date should be in between Project Start & End date.") {
        openNotification(
          resp.message,
          <CloseCircleFilled style={{ color: "#ff4d4f" }} />
        );
      } else {
        openNotification(
          'Employees Linked Successfully !!',
          <CheckCircleFilled style={{ color: "#52c41a" }} />
        );
      }
      form.resetFields();
      setSelectedProject(null);
      getLinkedEmployeeTable();
      linkedEmp(true);
    } catch (error) {
      form.resetFields();
      openNotification(
        "Failed to Add Link Employees",
        <CloseCircleFilled style={{ color: "#ff4d4f" }} />
      );
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
      setEmployees(deliveryManager);
      setSelectedEmployee([]);
      form.resetFields();
      setValue("Project");
      hide(false);
      setSelectedProject(null);
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (selectedNewProject) {
      form.resetFields();
      setSelectedProject(selectedNewProject.projectId);
      let projectDetails = employeeLinkedProject?.find(
        (proj) => proj.projectId == selectedNewProject.projectId
      );
      if (projectDetails) {
        const projectEmp = projectDetails.employees || [];
        projectDetails = { ...projectDetails, employees: projectEmp };
        setValue(
          selectedNewProject.isInternal ? "Internal Project" : "Project"
        );

        form.setFieldsValue({
          projectId: {
            label: projectDetails.projectName,
            value: projectDetails.projectId,
          },
          employees:
            projectDetails.employees.length > 0
              ? projectDetails.employees.map((employee) => ({
                  employeeNo: {
                    label: employee.name,
                    value: employee.employeeNo,
                  },
                  teamId: employee.department,
                  estimatedHours: employee.allocatedHours,
                  startDate: moment(employee.startDate),
                  isBillable: employee.isBillable,
                  Active: employee.isActive,
                  endDate: employee.endDate ? moment(employee.endDate) : null,
                }))
              : [{}],
        });
        if (projectDetails.deliveryManager !== "") {
          form.setFieldsValue({
            deliveryManagerId: {
              label: projectDetails.deliveryManager,
              value: projectDetails.deliveryManagerId,
            },
          });
        }
        if (projectDetails.productManager !== "") {
          form.setFieldsValue({
            productManagerId: {
              label: projectDetails.productManager,
              value: projectDetails.productManagerId,
            },
          });
        }
        const selectedEmployees = projectDetails.employees.map((employee) => ({
          value: employee.employeeNo,
          label: employee.name,
        }));
        setSelectedEmployee(selectedEmployees);
        const remainingEmployees = deliveryManager.filter(
          (employee) =>
            !selectedEmployees.some(
              (selected) => selected.value === employee.value
            )
        );
        const remainingEmployees2 = productManager.filter(
          (employee) =>
            !selectedEmployees.some(
              (selected) => selected.value === employee.value
            )
        );
        setEmployees(remainingEmployees);
      }
    } else {
      form.resetFields();
    }
  }, [selectedNewProject]);

  useEffect(() => {
    if (selectedProject && employeeLinkedProject) {
      form.resetFields();
      let projectDetails = employeeLinkedProject?.find(
        (proj) => proj.projectId == selectedProject
      );

      if (projectDetails) {
        const projectEmp = projectDetails?.employees || [];
        projectDetails = { ...projectDetails, employees: projectEmp };

        const updatedEmployees = projectEmp.map((employee) => {
          const department = projectDetails?.departments?.find(
            (dep) => dep.department === employee.department
          );

          return {
            employeeNo: {
              label: employee.name,
              value: employee.employeeNo,
            },
            teamId: employee.department,
            estimatedHours: employee.allocatedHours,
            startDate: moment(employee.startDate),
            isBillable: employee.isBillable,
            Active: employee.isActive,
            endDate: employee.endDate ? moment(employee.endDate) : null,
            totalHours: department ? department.hours : 0,
          };
        });

        form.setFieldsValue({
          projectId: {
            label: projectDetails.projectName,
            value: projectDetails.projectId,
          },
          deliveryManagerId: {
            label: projectDetails.deliveryManager,
            value: projectDetails.deliveryManagerId,
          },
          productManagerId: {
            label: projectDetails.productManager,
            value: projectDetails.productManagerId,
          },
          employees: updatedEmployees.length > 0 ? updatedEmployees : [{}],
        });

        const selectedEmployees = projectDetails.employees.map((employee) => ({
          value: employee.employeeNo,
          label: employee.name,
        }));

        setSelectedEmployee(selectedEmployees);
        setEmployees(
          deliveryManager.filter(
            (employee) =>
              !selectedEmployees.some(
                (selected) => selected.value === employee.value
              )
          )
        );

        updatedEmployees.forEach((employee, index) => {
          if (employee.estimatedHours) {
            handleEmployeeUtilization(employee.estimatedHours, index);
          }
        });
      } else {
        form.setFieldsValue({ projectId: selectedProject });
        form.setFieldsValue({ employees: [{}] });
      }
    }
  }, [selectedProject, employeeLinkedProject]);

  const onChange = (e) => {
    setValue(e.target.value);
    form.resetFields();
  };

  const handleBillable = (index, e) => {
    const newFields = [...form.getFieldValue("employees")];
    newFields[index].isBillable = e.target.checked;
    form.setFieldsValue({ employees: newFields });
  };

  const handleRemove = (name) => {
    // const employeeToRemove = form.getFieldValue([
    //   "employees",
    //   name,
    //   "employee",
    // ]);
    const employeeToRemove = form.getFieldValue("employees");
    console.log("employeeToRemove: ", employeeToRemove.length);
    console.log("totalEmployees: ", totalEmployees);
    console.log("name: ", name);
    console.log("isEmployeeLinked: ", isEmployeeLinked);
    
    if (isEmployeeLinked && name == 0 && totalEmployees == employeeToRemove.length) {
      setIsEmployeeLinked(false);
      setTotalEmployees(0)
    } else if (name == 0 && employeeToRemove.length == 1) {
      setIsEmployeeLinked(false)
    } else {
      setIsEmployeeLinked(true)
    }
    // if (employeeToRemove) {
    //   const removedEmployee = selectedEmployee.find(
    //     (employee) => employee.value === employeeToRemove
    //   );
    //   setEmployees((prev) => [...prev, removedEmployee]);
    //   setSelectedEmployee((prev) =>
    //     prev.filter((employee) => employee.value !== employeeToRemove)
    //   );
    // }
  };

  const handleActive = (index, e) => {
    const newFields = [...form.getFieldValue("employees")];
    newFields[index].Active = e.target.checked;
    form.setFieldsValue({ employees: newFields });
  };

  const handleEmployees = (event, index) => {
    const selectedEmployeeObj = employees.find(
      (employee) => employee.value === event.value
    );
    // Get the current list of employees from the form
    const currentEmployees = form.getFieldValue("employees");
    // Find the employee that is being replaced (if any)
    const oldEmployee = currentEmployees[index]?.employee;
    // Remove the old employee from the selectedEmployee list
    setSelectedEmployee((prevSelected) => {
      const newSelected = prevSelected.filter(
        (employee) => employee.value !== oldEmployee
      );
      newSelected[index] = selectedEmployeeObj;
      // Update the employees list to exclude the new selected employees
      setEmployees(
        deliveryManager.filter(
          (employee) =>
            !newSelected.some((selected) => selected.value === employee.value)
        )
      );
      return newSelected;
    });

    // Update the form's employee field with the new selection
    const newEmployees = [...currentEmployees];
    newEmployees[index].employeeNo = event;
    form.setFieldsValue({ employees: newEmployees });
  };

  const disabledStartDate = (currentDate) => {
    const projectStartDate = project?.find(
      (proj) => proj.value === selectedNewProject?.projectId
    )?.startDate;
    const internalProjectStartDate = internalProject?.find(
      (proj) => proj.value === selectedNewProject?.projectId
    )?.startDate;

    const startDate =
      projectStartDate || internalProjectStartDate
        ? moment(projectStartDate || internalProjectStartDate, "YYYY-MM-DD")
        : null;
    return startDate && currentDate.isBefore(startDate, "day");
  };

  const handleDepartmentChange = (value, fieldname) => {
    const project = employeeLinkedProject.find(
      (pro) => pro.projectId === selectedProject
    );
    const department = project?.departments.find(
      (dep) => dep.department == value
    );

    if (department) {
      form.setFields([
        {
          name: ["employees", fieldname, "totalHours"],
          value: department.hours,
        },
      ]);
    }

    const employee = form.getFieldValue(["employees", fieldname]);
    if (employee) {
      const estimatedHours = employee.estimatedHours;
      handleEmployeeUtilization(estimatedHours, fieldname);
    }
  };

  const handleEmployeeUtilization = (value, fieldname) => {
    const employees = form.getFieldValue("employees");
    const employee = employees && employees[fieldname];

    if (employee) {
      const totalHours = employee.totalHours;
      const startDate = employee.startDate;
      const department = employee.teamId;

      const hours = Number(value);

      if (hours) {
        if (totalHours) {
          const allocatedHours = allocatedHoursMap[department] || 0;
          const newTotalAllocated = allocatedHours + hours;

          if (newTotalAllocated > totalHours) {
            form.setFields([
              {
                name: ["employees", fieldname, "estimatedHours"],
                // errors: ["Allocated hours exceed total available hours!"],
              },
            ]);
          }

          setAllocatedHoursMap((prev) => ({
            ...prev,
            [department]: newTotalAllocated,
          }));

          const employeeUtilization = (hours / totalHours) * 100;
          const roundedUtilization = employeeUtilization.toFixed(2);

          form.setFields([
            {
              name: ["employees", fieldname, "utilization"],
              value: roundedUtilization,
            },
          ]);
        }

        const hoursPerDay = 8;
        const estimatedDays = hours / hoursPerDay;
        const allocatedEndDate = new Date(startDate);
        allocatedEndDate.setDate(allocatedEndDate.getDate() + estimatedDays);

        form.setFields([
          {
            name: ["employees", fieldname, "endDate"],
            value: dayjs(allocatedEndDate),
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (selectedNewProject && departmentOptions.length) {
      const updatedEmployees = form.getFieldValue("employees") || [];

      const newEmployees = updatedEmployees.map((employee, index) => {
        const project = employeeLinkedProject.find(
          (pro) => pro.projectId === selectedProject
        );

        const department = project?.departments.find(
          (dep) => dep.department === employee.teamId
        );

        return {
          ...employee,
          totalHours: department ? department.hours : 0,
        };
      });

      form.setFieldsValue({ employees: newEmployees });

      newEmployees.forEach((employee, index) => {
        if (employee.estimatedHours) {
          handleEmployeeUtilization(employee.estimatedHours, index);
        }
      });
    }
  }, [selectedNewProject, departmentOptions]);

  return (
    <>
      {formLoading ? (
        <div style={{ width: "800px" }}>
          {" "}
          <Spin
            style={{
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>
      ) : (
        <>
          <Form
            form={form}
            layout={"vertical"}
            initialValues={{
              layout: "vertical",
              remember: true,
            }}
            onFinish={onFinish}
            style={{
              padding: "1.5rem 0rem 0rem",
              margin: "auto",
              overflow: "hidden",
              width: "800px",
            }}
            className="userForm"
          >
            <div
              className="popupDiv"
              style={{
                maxHeight: "65vh",
                overflowY: "auto",
              }}
            >
              <Form.Item style={{ margin: "0 auto 24px" }}>
                <Radio.Group
                  options={radioOptions}
                  onChange={onChange}
                  value={value}
                  optionType="button"
                  disabled={selectedNewProject}
                  style={{
                    width: "100%",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="radiogroup"
                />
              </Form.Item>
              <Form.Item
                name="projectId"
                label={<Text style={{ fontSize: "16px" }}>Project Name</Text>}
                rules={[
                  {
                    required: true,
                    message: "Please Select Project",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Project Name"
                  size="large"
                  optionFilterProp="children"
                  options={value === "Project" ? project : internalProject}
                  onChange={(val) => {
                    setSelectedProject(val);
                    form.setFieldsValue({ projectId: val });
                  }}
                  disabled={selectedNewProject}
                  filterOption={filterOption}
                  value={selectedProject}
                />
              </Form.Item>

              <div
                style={{
                  gap: "12px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Form.Item
                  name="deliveryManagerId"
                  label={
                    <Text style={{ fontSize: "16px" }}>
                      {
                      // value === "Project"
                        // ? 
                        "Delivery Manager"
                        // : "Project Lead"
                        }
                    </Text>
                  }
                  rules={[
                    {
                      required: true,
                      message: `Please Select ${
                        // value === "Project"
                          // ? 
                          "Delivery Manager"
                          // : "Project Lead"
                      }`,
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Select
                    showSearch
                    placeholder={
                      // value === "Project" ? 
                      "Delivery Manager" 
                      // : "Project Lead"
                    }
                    size="large"
                    options={deliveryManager}
                    filterOption={filterOption}
                    optionFilterProp="children"
                  />
                </Form.Item>
                <Form.Item
                  name="productManagerId"
                  label={
                    <Text style={{ fontSize: "16px" }}>
                      {"Product Manager"}
                    </Text>
                  }
                  rules={[
                    {
                      required: true,
                      message: `Please Select ${"Product Manager"}`,
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Select
                    showSearch
                    placeholder={"Product Manager"}
                    size="large"
                    options={productManager}
                    filterOption={filterOption}
                    optionFilterProp="children"
                  />
                </Form.Item>
              </div>

              <Form.List
                name="employees"
                initialValue={[{}]}
                style={{ position: "absolute" }}
              >
                {(fields, { add, remove }) => {
                  const handleAddRow = () => {
                    add({}, 0);
                    setIsEmployeeLinked(true);
                    const totalEmployees = form.getFieldValue("employees");                    
                    setTotalEmployees(totalEmployees.length)
                  };
                  return (
                    <>
                      <Row
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "8px",
                        }}
                      >
                        <span>
                          {
                            <span
                              style={{
                                color: "red",
                                fontSize: "15px",
                                marginRight: "6px",
                              }}
                            >
                              *
                            </span>
                          }
                          Link Employees
                        </span>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            cursor: "pointer",
                          }}
                          onClick={handleAddRow}
                        >
                          {" "}
                          <span>{<PlusOutlined />}</span>{" "}
                          <span>Add Employee</span>
                        </div>
                      </Row>

                      <Table
                        dataSource={fields}
                        rowKey="key"
                        pagination={false}
                        style={{
                          border: "1px solid #D9D9D9",
                          borderRadius: "8px",
                          overflow: "hidden",
                          width: "100%",
                          overflowX: "auto",
                        }}
                        scroll={{ x: 1300 }}
                        columns={[
                          {
                            title: "Name",
                            dataIndex: "name",
                            width: "170px",
                            render: (_, field, index) => (
                              <Form.Item
                                name={[field.name, "employeeNo"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Select Employee",
                                  },
                                ]}
                                style={{ marginBottom: "0px", width: "max-content" }}
                              >
                                <Select
                                  placeholder="Add Employee"
                                  style={{ maxWidth: "170px", width: "170px" }}
                                  onChange={(event) => {
                                      handleEmployees(event, index)
                                      setIsEmployeeLinked(true)
                                    }
                                  }
                                  options={employees}
                                  labelInValue
                                  // disabled={disabledFields.includes(index)}
                                  filterOption={filterOption}
                                  optionFilterProp="children"
                                  showSearch
                                />
                              </Form.Item>
                            ),
                          },
                          {
                            title: "Department",
                            dataIndex: "department",
                            width: "200px",
                            render: (_, field, index) => (
                              <Form.Item
                                name={[field.name, "teamId"]}
                                style={{ marginBottom: "0px", width: "max-content" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Department",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Select Team"
                                  optionFilterProp="children"
                                  options={departmentOptions}
                                  style={{ width: "100%" }}
                                  filterOption={filterOption}
                                  onChange={(value) => {
                                      handleDepartmentChange(value, field.name)
                                      setIsEmployeeLinked(true)
                                    }
                                  }
                                />
                              </Form.Item>
                            ),
                          },
                          {
                            title: "Start Date",
                            dataIndex: "startDate",
                            width: "160px",
                            render: (_, field, index) => (
                              <Form.Item
                                // {...restField}
                                name={[field.name, "startDate"]}
                                style={{ marginBottom: "0px", width: "max-content" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  format={{
                                    format: "YYYY-MM-DD",
                                    type: "mask",
                                  }}
                                  disabledDate={disabledStartDate}
                                  // disabled={disabledFields.includes(index)}
                                  onChange={() => {
                                    const employee = form.getFieldValue([
                                      "employees",
                                      field.name,
                                    ]);
                                    // console.log(employee)
                                    if (employee) {
                                      handleEmployeeUtilization(
                                        employee.estimatedHours,
                                        field.name
                                      );
                                    }
                                    setIsEmployeeLinked(true)
                                  }}
                                />
                              </Form.Item>
                            ),
                          },
                          {
                            title: "Total Hours",
                            dataIndex: "totalHours",
                            width: "120px",
                            render: (_, field, index) => (
                              <Form.Item
                                name={[field.name, "totalHours"]}
                                style={{ marginBottom: "0px", width: "max-content" }}
                              >
                                <Input disabled style={{ width: "100%" }} />
                              </Form.Item>
                            ),
                          },
                          {
                            title: "Allocated Hours",
                            dataIndex: "hours",
                            width: "140px",
                            render: (_, field) => {
                              const fieldname = field.name;

                              return (
                                <Form.Item
                                  name={[field.name, "estimatedHours"]}
                                  style={{ marginBottom: "0px", width: "max-content" }}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please Select Estimated Project hours",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Total Estimated Time"
                                    type="number"
                                    min={0}
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        handleEmployeeUtilization(
                                          e.target.value,
                                          fieldname
                                        )
                                        setIsEmployeeLinked(true)
                                      }
                                    }
                                  />
                                </Form.Item>
                              );
                            },
                          },
                          {
                            title: "Utilization",
                            dataIndex: "utilization",
                            width: "100px",
                            render: (_, field) => (
                              <Form.Item
                                name={[field.name, "utilization"]}
                                style={{ marginBottom: "0px", width: "max-content" }}
                              >
                                <Input disabled style={{ width: "100%" }} />
                              </Form.Item>
                            ),
                          },
                          {
                            title: "End Date",
                            dataIndex: "endDate",
                            width: "160px",
                            render: (_, field, index) => (
                              <Form.Item
                                // {...restField}
                                name={[field.name, "endDate"]}
                                style={{ marginBottom: "0px", width: "max-content" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Date",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  format={{
                                    format: "YYYY-MM-DD",
                                    type: "mask",
                                  }}
                                  //disabledDate={disabledStartDate}
                                  disabled
                                />
                              </Form.Item>
                            ),
                          },
                          ...(selectedNewProject?.isInternal
                            ? []
                            : [
                                {
                                  title: "Billable",
                                  dataIndex: "billable",
                                  width: "80px",
                                  render: (_, field, index) => (
                                    <Form.Item
                                      name={[field.name, "isBillable"]}
                                      valuePropName="checked"
                                      rules={[
                                        {
                                          required: false,
                                        },
                                      ]}
                                      style={{
                                        marginBottom: "0px",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                      initialValue={false}
                                    >
                                      <Checkbox
                                        onChange={(e) => {
                                            handleBillable(index, e)
                                            setIsEmployeeLinked(true)
                                          }
                                        }
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      ></Checkbox>
                                    </Form.Item>
                                  ),
                                },
                              ]),
                          {
                            title: "Active",
                            dataIndex: "active",
                            width: "80px",
                            render: (_, field, index) => (
                              <Form.Item
                                //    {...restField}
                                name={[field.name, "Active"]}
                                valuePropName="checked"
                                style={{
                                  marginBottom: "0px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}
                                initialValue={true}
                              >
                                <Checkbox
                                  onChange={(e) => {
                                      handleActive(index, e)
                                      setIsEmployeeLinked(true)
                                    }
                                  }
                                  style={{
                                    fontSize: "16px",
                                  }}
                                ></Checkbox>
                              </Form.Item>
                            ),
                          },
                          {
                            title: "Actions",
                            dataIndex: "actions",
                            width: "80px",
                            render: (_, field, index) => (
                              <>
                                  <Form.Item style={{ marginBottom: "0px" }}>
                                    <Tooltip title="Remove Employee">
                                      <span
                                        onClick={() => {
                                          handleRemove(field.name);
                                          remove(field.name);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <DeleteOutlined />
                                      </span>
                                    </Tooltip>
                                  </Form.Item>
                              </>
                            ),
                          },
                        ]}
                      />
                    </>
                  );
                }}
              </Form.List>
            </div>

            <Flex
              justify="end"
              gap="middle"
              style={{
                width: "100%",
                position: "relative",
                top: "0.5rem",
                marginLeft: "-16px",
              }}
              wrap="wrap"
            >
              {isEmployeeLinked ?
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                >
                  Save
                </Button>
              </Form.Item>
              : 
              <Form.Item>
                <Button
                  type="primary"
                  // htmlType="submit"
                  size="large"
                  disabled
                  loading={loading}
                >
                  Save
                </Button>
              </Form.Item>}
            </Flex>
          </Form>
        </>
      )}
      {contextHolder}
    </>
  );
};

export default AddNewTimeSheetForm;
