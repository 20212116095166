import React, { useEffect, useState } from "react";
import { Button, Flex, Input, message, notification } from "antd";
import ChatItem from "../../utils/ChatItem";
import { approval } from "../../api";
import { employeeId } from "../../utils/userDetails";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

const ChatCommentForm = ({
  refreshChat,
  projectId,
  year,
  month,
  hide,
  approverNo,
  timeSheet,
  setIsError,
  isError,
}) => {
  const { TextArea } = Input;
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };
  const [chatMessages, setChatMessages] = useState([]);
  const [currentComment, setCurrentComment] = useState(
    "Please approve my timesheet"
  );
  // const [isError, setIsError] = useState(false);

  const getChatMessagges = async () => {
    try {
      const response = await approval.getChatData({
        projectId,
        employeeNo: employeeId,
        year,
        month,
        approverNo,
      });
      const customChat = response?.comments.map((data) => ({
        employeeId: data.employeeNo,
        employeeName: data.employeeName,
        message: data.comment,
      }));
      setChatMessages(customChat);
    } catch (error) {
      console.log("error in chat response", error, error?.response?.data?.msg);
    }
  };

  useEffect(() => {
    setChatMessages([]);
    getChatMessagges();
  }, [refreshChat, approverNo]);

  const handleComment = (e) => {
    setCurrentComment(e.target.value);
    if (e.target.value) {
      setIsError(false); 
    }
  };

  const handleSendApproval = async () => {
    if (!currentComment) {
      setIsError(true);
      // openNotification(
      //   "Please add comment before submitting timesheet for approval",
      //   <CloseCircleFilled style={{ color: "#ff4d4f" }} />
      // );
      return;
    }
    try {
      const response = await approval.sendApproval({
        projectId,
        year,
        month,
        employeeNo: employeeId,
        comment: currentComment,
      });
      openNotification(
        response.message,
        <CheckCircleFilled style={{ color: "#52c41a" }} />
      );
      timeSheet();
    } catch (error) {
      openNotification(
        error?.response?.data?.error,
        <CloseCircleFilled style={{ color: "#ff4d4f" }} />
      );
    } finally {
      setCurrentComment(null);
      hide(false);
    }
  };  

  return (
    <div
      className="formScroll"
      style={{ marginBottom: "1rem", maxHeight: "700px", overflowY: "scroll" }}
    >
      <ChatItem messages={chatMessages} myEmployeeId={employeeId} />

      <Flex vertical gap={"1.5rem"}>
        <div style={{ display: "flex" }}>
          <span style={{ color: "red" }}>*</span>
          <span>Add comments before submitting timesheet for approval</span>
        </div>

        <div>

        <TextArea
          showCount
          maxLength={100}
          value={currentComment}
          onChange={handleComment}
          placeholder="Add Your Comment"
          style={{
            height: 120,
            resize: "none",
            borderColor: isError ? "red" : "",
          }}
        />
        {isError && (
          <span style={{ color: "red" }}>
            * Please add comment before submitting timesheet for approval
          </span>
        )}
        </div>

        <Button
          type="primary"
          size="large"
          style={{ width: "120px", alignSelf: "end" }}
          onClick={handleSendApproval}
        >
          Send
        </Button>
        {contextHolder}
      </Flex>
    </div>
  );
};

export default ChatCommentForm;
