import React, { useEffect, useState } from "react";
import { approval, deliveryManager } from "../../api";
import { Button, Form, Input, message, notification } from "antd";
import ChatItem from "../../utils/ChatItem";
import { employeeId } from "../../utils/userDetails";
import { CheckCircleFilled } from "@ant-design/icons";

const ApproveForm = ({ data, hide, open, projectId, month, year }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [chatMessages, setChatMessages] = useState([]);
  const [currentComment, setCurrentComment] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };
  useEffect(() => {
    setChatMessages([]);
    getChatMessages();
    form.resetFields();
  }, [open, form]);

  const getChatMessages = async () => {
    try {
      const response = await approval.getChatData({
        projectId,
        employeeNo: data.employeeNo,
        approverNo: employeeId,
        year,
        month,
      });
      const customChat = response?.comments.map((data) => ({
        employeeId: data.employeeNo,
        employeeName: data.employeeName,
        message: data.comment,
      }));
      setChatMessages(customChat);
    } catch (error) {
      console.log("Error in chat response", error);
    }
  };

  const handleComment = (e) => {
    setCurrentComment(e.target.value);
  };

  const approveOrRejectTimesheet = async (status, msg = "") => {
    try {
      const response = await deliveryManager.approveorrejectTimesheet({
        projectId,
        approverEmployeeNo: employeeId,
        statusId: status,
        employeeNo: data.employeeNo,
        comment: currentComment ? currentComment : msg,
        year,
        month,
      });
      message.success("Timesheet processed successfully");
    } catch (error) {
      message.error(error?.response?.data?.msg || "Error processing timesheet");
    } finally {
      setCurrentComment("");
      hide(false);
    }
  };

  const handleReject = () => {
    form
      .validateFields()
      .then((values) => {
        approveOrRejectTimesheet(4,"Rejected!");
        setCurrentComment("");
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleApprove = () => {
    approveOrRejectTimesheet(3, "Approved!");
  };

  return (
    <div
      className="formScroll"
      style={{ marginBottom: "1rem", maxHeight: "700px", overflowY: "scroll" }}
    >
      <ChatItem messages={chatMessages} myEmployeeId={employeeId} />
      <Form form={form} onFinish={handleReject}>
        <Form.Item
          name="comment"
          rules={[
            {
              required: true,
              message: "Please add comment",
            },
          ]}
        >
          <TextArea
            showCount
            maxLength={100}
            value={currentComment}
            onChange={handleComment}
            placeholder="Add Your Comment"
            style={{
              height: 120,
              resize: "none",
            }}
          />
        </Form.Item>
        {data.statusId !== 2 && (
          <div
            style={{
              color: "#e23243",
              marginTop: "1rem",
              fontSize: "14px",
              fontWeight: "550",
            }}
          >
            {`*This request ${
              data.statusId === 1
                ? "is Not in Draft"
                : data.statusId === 3
                ? "is Approved"
                : data.statusId === 4 && "is Rejected"
            }`}
          </div>
        )}
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "flex-end",
            marginTop: "2rem",
          }}
        >
          <Button
            type="primary"
            // size="large"
            style={{
              backgroundColor: data.statusId !== 2 ? "#f5f5f5" : "#e23243",
              color: data.statusId !== 2 ? "#d9d9d9" : "#fff",
              borderColor: data.statusId !== 2 ? "#d9d9d9" : "#e23243",
            }}
            htmlType="submit"
            disabled={data.statusId !== 2}
          >
            Reject
          </Button>
          <Button
            type="primary"
            onClick={handleApprove}
            disabled={data.statusId !== 2}
            style={{
              backgroundColor: data.statusId !== 2 ? "#f5f5f5" : "#009335",
              color: data.statusId !== 2 ? "#d9d9d9" : "#fff",
              borderColor: data.statusId !== 2 ? "#d9d9d9" : "#009335",
            }}
          >
            Approve
          </Button>
        </div>
        {contextHolder}
      </Form>
    </div>
  );
};

export default ApproveForm;
