import {
  Button,
  Form,
  Checkbox,
  Col,
  Flex,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import InternalProjectForm from "../components/Form/InternalProjectForm";
import TableComponent from "../utils/TableComponent";
import { EditOutlined } from "@ant-design/icons";
import { Project } from "../api";
import dayjs from "dayjs";

const AddProject = () => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectAdded, setProjectAdded] = useState(false);
  const [clientProjects, setClientProjects] = useState(false);
  const [internalProjects, setInternalProjects] = useState(false);
  const [checked, setChecked] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: resources?.length,
  });


  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      change: true,
      width: "10%",
    },
    {
      title: "Delivery Manager",
      dataIndex: "deliveryManagerName",
      key: "deliveryManagerName",
      width: "10%",
      change: true,
    },
    {
      title: "Product Manager",
      dataIndex: "productManagerName",
      key: "productManagerName",
      width: "10%",
      change: true,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: "7.5%",
      change: true,
      render: (date) => {
        return dayjs(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Est. Hours",
      dataIndex: "departments",
      key: "departments",
      width: "6%",
      render: (departments) => {
        if (!departments || departments.length === 0) return 0;
        return departments.reduce(
          (total, dept) => total + (dept.hours || 0),
          0
        );
      },
    },
    {
      title: "Description",
      dataIndex: "projectDescription",
      key: "projectDescription",
      width: "18%",
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    ...(!checked
      ? [
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            fixed: "right",
            hideField: true,
            width: "2%",
            render: (_, record) => (
              <Space size="middle">
                <Tooltip title="Edit">
                  <a onClick={() => handleEdit(record)}>
                    <EditOutlined style={{ fontSize: "15px" }} />
                  </a>
                </Tooltip>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const handleEdit = (record) => {
    setSelectedProject(record);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    form.resetFields()
    setSelectedProject(null);
    setIsModalVisible(false);
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      total: resources.length,
    });
  };

  const onChange = async (e) => {
    await setChecked(e.target.checked);
    if (e.target.checked) {
      setResources(clientProjects);
    } else {
      setResources(internalProjects);
    }
  };

  const getAllProjects = async () => {
    try {
      setLoading(true);
      const response = await Project.getProjects();
      setInternalProjects(response.internalProjects);
      setClientProjects(response.projects);
      if (checked) {
        setResources(response.projects);
      } else {
        setResources(response.internalProjects);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  useEffect(() => {
    if (projectAdded) {
      setSelectedProject(null);
      getAllProjects();
      setProjectAdded(false);
    }
  }, [projectAdded]);

  return (
    <div style={{ padding: "1rem 0 0 0" }}>
      <Row justify="start" align="middle">
        <Col>
          <Title level={3} style={{ margin: "0", padding: "0 0 0 1rem" }}>
            Project Details
          </Title>
        </Col>
      </Row>
      <Flex vertical gap="1rem" style={{ margin: "0 1rem" }}>
        <Flex align="center" justify="space-between">
          <Checkbox
            onChange={onChange}
            checked={checked}
            style={{ color: "#4097ff", marginTop: "10px" }}
          >
            View Client Projects
          </Checkbox>
          <Button
            type="primary"
            size="large"
            onClick={() => setIsModalVisible(true)}
            style={{ width: "fit-content", margin: "0 1rem", alignSelf: "end" }}
          >
            Add Project
          </Button>
        </Flex>
        <TableComponent
          loading={loading}
          rows={resources}
          columns={columns}
          onChange={handleTableChange}
          locale={{ emptyText: "No data available for given input" }}
        />
      </Flex>
      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
            {selectedProject
              ? `Edit Project: ${selectedProject.projectName}`
              : "Add Project"}
          </div>
        }
        // visible={isModalVisible}
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={"fit-content"}
      >
        <InternalProjectForm
          hide={setIsModalVisible}
          added={setProjectAdded}
          project={selectedProject}
          isEdit={!!selectedProject}
          form={form}
        />
      </Modal>
    </div>
  );
};

export default AddProject;
