import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Button, Form, TimePicker, Typography, notification } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { employeeApi } from "../../api";
import { employeeId } from "../../utils/userDetails";

const EditableEmployeeViewForm = ({ hide, selectedDay, formSubmit }) => {
  const format = "HH:mm";
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { Text } = Typography;
  const reactQuillRef = useRef();
  
  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };

  useEffect(() => {
    if (selectedDay) {
      const hours = Math.floor(selectedDay.minutesWorked / 60);
      const minutes = selectedDay.minutesWorked % 60;
      form.setFieldsValue({
        description: selectedDay.description,
        timeWorked: dayjs(
          `${hours}:${minutes < 10 ? "0" + minutes : minutes}`,
          format
        ),
      });
    }
  }, [selectedDay, form]);

  const onFinish = async (values) => {
    const timeWorked = values.timeWorked;
    const minutesWorked = timeWorked.hour() * 60 + timeWorked.minute();

    try {
      setLoading(true);
      let projectOrActivityId;
      if (selectedDay.project) {
        projectOrActivityId = { projectId: selectedDay.project };
      } else {
        projectOrActivityId = { activityId: selectedDay.activityId };
      }
      const resp = await employeeApi.addTask({
        ...projectOrActivityId,
        logDate: `${selectedDay.year}-${
          selectedDay.month + 1 < 10
            ? "0" + (selectedDay.month + 1)
            : selectedDay.month + 1
        }-${selectedDay.date < 10 ? "0" + selectedDay.date : selectedDay.date}`,
        minutesWorked: minutesWorked,
        description: values.description,
        employeeNo: employeeId,
      });
      formSubmit(true);
      if (resp.message == "Project is already approved and cannot be updated") {
        openNotification(
          resp.message,
          <CloseCircleFilled style={{ color: "#ff4d4f" }} />
        );
      } else {
        openNotification(
          "Log updated successfully!",
          <CheckCircleFilled style={{ color: "#52c41a" }} />
        );
      }
    } catch (error) {
      hide();
      openNotification(
        "Failed to Update Logs!!",
        <CloseCircleFilled style={{ color: "#ff4d4f" }} />
      );
      console.error("Error occurred:", error);
    } finally {
      hide();
      setLoading(false);
    }
  };

  const handleDescription = (value) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const textContent = doc.body.textContent;

    if (textContent) {
      form.setFieldsValue({
        description: value,
      });
    } else {
      form.setFieldsValue({
        description: "",
      });
    }
  };
  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() > 2000 && event.key !== 'Backspace')
      event.preventDefault();
  };
  return (
    <Form
      form={form}
      layout={"vertical"}
      initialValues={{
        layout: "vertical",
        remember: true,
      }}
      onFinish={onFinish}
      style={{
        padding: "0rem",
        margin: "auto",
        overflow: "hidden",
      }}
      className="userForm"
    >
      <div className="form">
        <div
          style={{
            fontSize: "24px",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          {`Edit Log For ${
            selectedDay.date < 10 ? "0" + selectedDay.date : selectedDay.date
          }-${
            selectedDay.month + 1 < 10
              ? "0" + (selectedDay.month + 1)
              : selectedDay.month + 1
          }-${selectedDay.year}`}
        </div>
        <Form.Item
          name="timeWorked"
          label={<div style={{ fontSize: "16px" }}>Log Time</div>}
          rules={[
            {
              required: true,
              message: "Please Enter Time",
            },
          ]}
        >
          <TimePicker
            placeholder="Enter Worked Time" 
            needConfirm={false}
            format={format}
            minuteStep={5}
            style={{ width: "100%" }}
            hideDisabledOptions
            disabledHours={() =>
              selectedDay.leaveType === 2
                ? [...Array(24).keys()].filter((hour) => hour > 3)
                : [...Array(24).keys()].filter((hour) => hour > 14)
            }
            showNow={false}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label={<Text style={{ fontSize: "16px" }}>Description</Text>}
          rules={[
            {
              required: true,
              message: "Please add Description",
            },
          ]}
        >
          <div
            style={{
              borderRadius: "2px",
              overflow: "hidden",
              zIndex: "10",
              height: "250px",
              marginBottom: "1rem",
            }}
          >
            <ReactQuill
              style={{
                width: "100%",
                height: "200px",
              }}
              ref={reactQuillRef}
              onKeyDown={checkCharacterCount}
              value={selectedDay.description}
              onChange={handleDescription}
              theme="snow"
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
            />
          </div>
        </Form.Item>
      </div>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          disabled={selectedDay.leaveType === 1}
          style={{ display: "block", width: "100%", marginTop: "1rem" }}
        >
          Save
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
};

export default EditableEmployeeViewForm;
