import { Flex, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { adminEmployeeView, linkEmployees } from "../api";
import EmployeeViewForm from "../components/Form/EmployeeViewForm";
import TableComponent from "../utils/TableComponent";
import { convertTime } from "../utils/dateFormat";
import {
  employeeId,
  isDeliveryManager,
  timesheetAdmin,
} from "../utils/userDetails";

const generateCalendarData = (year, month) => {
  const startOfMonth = moment().year(year).month(month-1).startOf("month");
  const endOfMonth = moment(startOfMonth).endOf("month");

  let currentDate = startOfMonth.clone();
  const days = [];

  while (currentDate <= endOfMonth) {
    days.push({
      date: currentDate.date(),
      day: currentDate.format("dddd").slice(0, 3).toUpperCase(),
      dayOfWeek: currentDate.day(),
      isWeekend: currentDate.day() === 0 || currentDate.day() === 6,
    });
    currentDate.add(1, "day");
  }
  return days;
};

const EmployeeViewTimeSheet = () => {
  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthIndex = new Date().getMonth();
  const currentDate = moment();
  const [currentMonth, setCurrentMonth] = useState(currentDate.month() + 1);
  const [currentYear, setCurrentYear] = useState(currentDate.year());
  const [availableMonths, setAvailableMonths] = useState(
    monthArray.slice(0, currentMonthIndex + 1)
  );
  const [employee, setEmployee] = useState(null);
  const [employeeArray, setEmployeeArray] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [expandedWeeks, setExpandedWeeks] = useState({ 0: true });
  const [employeeDays, setEmployeeDays] = useState("");

  const handleYearChange = (value) => {
    setCurrentYear(value);

    if (value < currentDate.year()) {
      setAvailableMonths(monthArray);
      setCurrentMonth(currentMonthIndex);
    } else if (value === currentDate.year()) {
      setAvailableMonths(monthArray.slice(0, currentMonthIndex + 1));
      setCurrentMonth(currentMonthIndex+1);
    } else {
      setAvailableMonths([]);
    }
  };

  const handleMonthChange = (value) => {
    setCurrentMonth(value);
  };

  const handleEmployeeChange = (value) => {
    setEmployee(value);
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      total: employeeData.length,
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const createWeeks = (calendarData) => {
    const weeks = [];
    let currentWeek = [];
    const startDay = calendarData[0].dayOfWeek;

    for (let i = 0; i < startDay; i++) {
      currentWeek.push(null);
    }
    calendarData.forEach((item, index) => {
      currentWeek.push(item);
      if (item.dayOfWeek === 6 || index === calendarData.length - 1) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });

    while (currentWeek.length > 0 && currentWeek.length < 7) {
      currentWeek.push(null);
    }
    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }
    if (weeks.length === 6 && !weeks[5].some((day) => day !== null)) {
      weeks.pop();
    }
    return weeks;
  };

  const calendarData = generateCalendarData(currentYear, currentMonth);
  const weeks = createWeeks(calendarData);

  const toggleWeek = (weekIndex) => {
    setExpandedWeeks((prevState) => ({
      ...prevState,
      [weekIndex]: !prevState[weekIndex],
    }));
  };

  const columns = [
    {
      title: "Project / Activity",
      dataIndex: "projectOrActivity",
      key: "projectOrActivity",
      fixed: "left",
      width: 180,
    },
    {
      title: "Project Manager",
      dataIndex: "deliveryManagerName",
      key: "deliveryManagerName",
      fixed: "left",
      width: 170,
    },
    {
      title: "Utilization %",
      dataIndex: "totals",
      key: "totals",
      fixed: "left",
      width: 120,
      render: (text, record) => {
        const totalUtilization = employeeData?.reduce((sum, rec) => {
          const totalMinutes = rec?.logs?.reduce((acc, curr) => acc + (curr.minutes || 0), 0) || 0;
          const totalHours = totalMinutes / 60;
          const utilization =
            rec.totals && totalHours > 0
              ? (totalHours / (rec.totals.totalWorkingDays * 8)) * 100
              : 0;
        
          return sum + utilization;
        }, 0).toFixed(2);
    
        // If row is "Total worked", return total utilization
        if (record.projectOrActivity === "Total worked") {
          return <div style={{ textAlign: "center", fontWeight: "bold" }}>{totalUtilization}%</div>;
        }

        const totalMinutes = record?.logs?.reduce((acc, curr) => acc + (curr.minutes || 0), 0) || 0;
        const totalHours = totalMinutes / 60;
        const utilization =
          record.totals && totalHours > 0
            ? ((totalHours / (record.totals.totalWorkingDays * 8)) * 100).toFixed(2)
            : "0";
  
        return <div style={{ textAlign: "center" }}>{utilization + "%"}</div>;
      },
    },
    ...weeks.map((week, weekIndex) => {
      const isExpanded = expandedWeeks[weekIndex];
      return {
        title: (
          <div
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => toggleWeek(weekIndex)} 
          >
            Week {weekIndex + 1} {isExpanded ? "↑" : "↓"}
          </div>
        ),
        width: 100,
        children: isExpanded
          ? week
              .filter((item) => item !== null)
              .map((item, dayIndex) => {  
                return {
                  title: (
                    <div style={{ textAlign: "center" }}>
                      <div>{item.day}</div>
                      <div style={{ fontSize: "14px" }}>
                        {item.date} {monthArray[currentMonth - 1].slice(0, 3)}
                      </div>
                    </div>
                  ),
                  dataIndex: `day${item.date}`,
                  key: item.date,
                  width: 100,
                  change: false,
                  render: (text, record) => {
                    if (!record.logs[item.date - 1]) return null; // Handle cases where no logs are present
                    
                    const minutesWorked = record.logs[item.date - 1]?.minutes;
                    const description = record.logs[item.date - 1];
                    const employeeName = record.employeeName;
                    const isOnLeave = record.logs[item.date - 1]?.isOnLeave;
                    const leaveType = record.logs[item.date - 1]?.leaveType;
                    const Date = moment({
                      year: currentYear,
                      month: currentMonth-1,
                      date: item.date,
                    }).format("DD-MM-YYYY");
                    return {
                      children: (
                        <div
                          onClick={() => {
                            if (!item.isWeekend && record.name != "Total worked") {
                              setSelectedDay({
                                ...item,
                                month: monthArray[currentMonth-1].slice(0, 3),
                                // hoursWorked,
                                minutesWorked,
                                description: description,
                                employeeName,
                                Date,
                              });
                              setIsModalVisible(true);
                            }
                          }}
                          style={{
                            cursor: item.isWeekend ? "default" : "pointer",
                            height: 55,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            color:
                              minutesWorked / 60 >= 8
                                ? "#06c974"
                                : leaveType === 1 || leaveType == 2 || leaveType === 3
                                ? "#df0b2e"
                                : "#000000",
                          }}
                        >
                          {isOnLeave
                            ? leaveType === 1
                              ? "Leave"
                              : leaveType == 2
                              ? `Half-Day ${convertTime(minutesWorked)}`
                              : leaveType === 3
                              ? "Holiday"
                              : convertTime(minutesWorked)
                            : convertTime(minutesWorked)}
                        </div>
                      ),
                      props: {
                        style: {
                          background: item.isWeekend ? "pink" : "white",
                          padding: 0,
                        },
                      },
                    };
                  },
                };
              })
          : [],
      };
    }),
    {
      title: "Total Time",
      key: "totalHours",
      fixed: "right",
      width: 110,
      align: "center",
      render: (text, record) => {
        const totalMinutes = record?.logs.reduce(
          (acc, curr) => acc + curr.minutes,
          0
        );
        return <b>{convertTime(totalMinutes)}</b>;
      },
    },
  ];

  const getAllEmployees = async () => {
    try {
      const response = await linkEmployees.getAllEmployeesBasedOnProject({
        employeeNo: employeeId,
        admin: timesheetAdmin,
        deliveryManager: isDeliveryManager,
      });
      const newResponse = response.employees.map((employee) => {
        return {
          value: employee.employeeNo,
          label: `${employee.employeeName} (${employee.employeeNo})`,
        };
      });
      setEmployeeArray(newResponse);
      if (newResponse.length > 0) {
        setEmployee(newResponse[0].value);
      }
    } catch (error) {
      console.log("get all employee error", error);
    }
  };

  const employeeView = async () => {
    try {
      setLoading(true);
      const response = await adminEmployeeView.getEmployeeView({
        employeeId: employee,
        month: currentMonth,
        year: currentYear,
      });

      let newResponse;

      const totalWorkingDays = response?.totalWorkingDays || 0;

      const projectArray = response?.projects.map((row) => ({
        ...row,
        projectOrActivity: row.name,
        project: true,
      }));

      const activityArray = response?.activities.map((row) => ({
        ...row,
        projectOrActivity: row.name,
        project: false,
      }));

      const dayWiseTotalHoursArrayMapped = response?.dayWiseTotalHours.map(
        (row) => ({
          ...row,
          projectOrActivity: row.name,
        })
      );

      newResponse = [
        ...projectArray,
        ...activityArray,
        ...dayWiseTotalHoursArrayMapped,
      ];      
      setEmployeeData(newResponse);
      setEmployeeDays(totalWorkingDays);
    } catch (error) {
      console.log("employee view error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  useEffect(() => {
    if (employee && (currentMonth || currentMonth === 0) && currentYear) {
      setEmployeeData([]);
      employeeView();
    }
  }, [employee, currentMonth, currentYear]);

  const totalLoggedHours =
    employeeData
      .filter((item) => item.name === "Total worked")[0]
      ?.logs.reduce((sum, log) => sum + log.totalWorked, 0) || 0;
      
  const percentage = ((totalLoggedHours / (employeeDays * 8)) * 100).toFixed(1);

  const totalUtilization = employeeData?.reduce((sum, rec) => {
    const totalMinutes = rec?.logs?.reduce((acc, curr) => acc + (curr.minutes || 0), 0) || 0;
    const totalHours = totalMinutes / 60;
    const utilization =
      rec.totals && totalHours > 0
        ? (totalHours / (rec.totals.totalWorkingDays * 8)) * 100
        : 0;
  
    return sum + utilization;
  }, 0).toFixed(2);

  return (
    <Flex vertical gap="2rem" style={{ padding: 24 }}>
      <Flex justify="space-between" align="flex-start" gap="2rem" >
        <div style={{ display: "flex", gap: "1rem" }}>
          <div>
            <div className="selectDropDown">Year</div>
            <Select
              placeholder="Select Year"
              defaultValue={currentYear}
              onChange={handleYearChange}
              size="large"
              style={{ width: "100px", textAlign: "left" }}
            >
              {Array.from(
                { length: 5 },
                (_, i) => currentDate.year() - 4 + i
              ).map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="selectDropDown">Month</div>
            <Select
              placeholder="Select Month"
              value={currentMonth}
              onChange={handleMonthChange}
              size="large"
              style={{ width: "120px", textAlign: "left" }}
            >
              {availableMonths.map((month, index) => (
                <Select.Option key={index} value={index + 1}>
                  {month}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="selectDropDown">Employee</div>
            <Select
              placeholder="Select Employee"
              value={employee}
              onChange={handleEmployeeChange}
              size="large"
              style={{ width: "280px", textAlign: "left" }}
              options={employeeArray}
              filterOption={filterOption}
              optionFilterProp="children"
              showSearch
            />
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <div style={{ fontWeight: "500" }}>Total Working Hours :</div>
            <div style={{ width: "30px" }}>{employeeDays * 8 || 0}</div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <div style={{ fontWeight: "500" }}>Total Logged Hours :</div>
            <div style={{ width: "30px" }}>{totalLoggedHours || 0}</div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <div style={{ fontWeight: "500" }}>Total Percentage : </div>
            <div
              style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
            >
              {" "}
              <div
                style={{
                  backgroundColor:
                    percentage >= 92
                      ? "green"
                      : percentage >= 90
                      ? "yellow"
                      : "red",
                  width: "15px",
                  height: "15px",
                  borderRadius: "100%",
                }}
              ></div>
              {percentage || "0.0"}
            </div>
          </div>
        </div>
      </Flex>

      <TableComponent
        loading={loading}
        rows={employeeData}
        columns={columns}
        scrollx={1400}
        pagination={pagination}
        onChange={handleTableChange}
        summary={true}
        totalUtilization={totalUtilization}
        locale={{ emptyText: "No data available for given input" }}
      />

      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
            {`Task Details ${selectedDay?.Date}`}
          </div>
        }
        open={isModalVisible}
        // visible={isModalVisible}
        footer={null}
        width={550}
        onCancel={() => setIsModalVisible(false)}
        centered={true}
      >
        <EmployeeViewForm hide={setIsModalVisible} selectedDay={selectedDay} />
      </Modal>
    </Flex>
  );
};

export default EmployeeViewTimeSheet;
