import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Flex, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/employeeTimesheet.css";
import { linkEmployees } from "../api";
import TableComponent from "../utils/TableComponent";
import { IoCheckmarkOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";

export default function LinkEmployeeHistory() {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = location.state;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [dataSource, setDataSource] = useState([]);
  const [projectName, setProjectName] = useState("");
  const columns = [
    {
      title: "Employee",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "25%",
      change: "true",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      width: "20%",
      change: "true",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      width: "20%",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      align: "left",
      width: "15%",
      render: (text, record) => {
        return !text ? (
          <RxCross1
            style={{ margin: "0 0 0 1rem", color: "df0b2e", fontSize: "16px" }}
          />
        ) : (
          <IoCheckmarkOutline
            style={{ margin: "0 0 0 1rem", color: "green", fontSize: "18px" }}
          />
        );
      },
    },
    {
      title: "Billable",
      dataIndex: "isBillable",
      key: "isBillable",
      align: "left",
      width: "15%",
      render: (text, record) => {
        return !text ? (
          <RxCross1
            style={{ margin: "0 0 0 1rem", color: "df0b2e", fontSize: "16px" }}
          />
        ) : (
          <IoCheckmarkOutline
            style={{ margin: "0 0 0 1rem", color: "green", fontSize: "18px" }}
          />
        );
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const [errorShown, setErrorShown] = useState(false);

  const timeSheet = async () => {
    try {
      setLoading(true);
      const response = await linkEmployees.employeeHistory({
        projectId,
      });
      setProjectName(response.projectName);
      const filteredData = response.employees;
      if (filteredData) {
        filteredData?.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );
        const newDataSource = filteredData.map((item) => ({
          name: item.name,
          startDate: item.startDate,
          endDate: item.endDate,
          isActive: item.isActive,
          isBillable: item.isBillable,
        }));
        setDataSource(newDataSource);
      }
    } catch (error) {
      if (!errorShown) {
        setErrorShown(true);
        if (error.message === "Network Error") {
          message.error("Network connection failed. Try again.");
        } else {
          message.error("An error occurred while fetching data.");
        }
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (projectId) {
      timeSheet();
    }
  }, [projectId]);

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      total: dataSource.length,
    });
  };
  return (
    <Flex vertical gap={"0.5rem"} style={{ padding: "15px 24px" }}>
      <Tooltip placement="top" title="Back" color="8e8e8e" arrow={false}>
        <Button
          type="text"
          shape="circle"
          icon={
            <ArrowLeftOutlined style={{ fontSize: "17px", color: "#6d6d6d" }} />
          }
          style={{ padding: "4px" }}
          onClick={() => navigate("/add-timesheet")}
        />
      </Tooltip>

      <Flex
        vertical
        gap={"1rem"}
        style={{
          backgroundColor: "#e6f4ff",
          height: "55px",
          borderRadius: "1rem",
          width: "100%",
          padding: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Flex align="flex-start" gap={"5rem"}>
          <div style={{ fontSize: "1rem" }}>
            <b>Project: &nbsp;</b>
            {projectName}
          </div>
        </Flex>
      </Flex>
      <div
        className="formScroll"
        style={{ maxHeight: "70vh", overflowY: "scroll" }}
      >
        <TableComponent
          loading={loading}
          columns={columns}
          rows={dataSource}
          scroll={{ x: 1400 }}
          onChange={handleTableChange}
          locale={{ emptyText: "No data available for given input" }}
        />
      </div>
    </Flex>
  );
}
