import { Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoCheckmarkOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { adminProjectView } from "../api";
import EmployeeViewForm from "../components/Form/EmployeeViewForm";
import TableComponent from "../utils/TableComponent";
import { convertTime } from "../utils/dateFormat";

const generateCalendarData = (year, month) => {
  const startOfMonth = moment().year(year).month(month-1).startOf("month");
  const endOfMonth = moment(startOfMonth).endOf("month");

  let currentDate = startOfMonth.clone();
  const days = [];

  while (currentDate <= endOfMonth) {
    days.push({
      date: currentDate.date(),
      day: currentDate.format("dddd").slice(0, 3).toUpperCase(),
      dayOfWeek: currentDate.day(),
      isWeekend: currentDate.day() === 0 || currentDate.day() === 6,
    });
    currentDate.add(1, "day");
  }
  return days;
};

const ProjectViewEmp = ({currentMonth,currentYear,project}) => {
 const monthArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];  
  const [projectArray, setProjectArray] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const [expandedWeeks, setExpandedWeeks] = useState({ 0: true });
  
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      total: projectData.length,
    });
  };

  const createWeeks = (calendarData) => {
    const weeks = [];
    let currentWeek = [];
    const startDay = calendarData[0].dayOfWeek;

    for (let i = 0; i < startDay; i++) {
      currentWeek.push(null);
    }
    calendarData.forEach((item, index) => {
      currentWeek.push(item);
      if (item.dayOfWeek === 6 || index === calendarData.length - 1) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });

    while (currentWeek.length > 0 && currentWeek.length < 7) {
      currentWeek.push(null);
    }
    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }
    if (weeks.length === 6 && !weeks[5].some((day) => day !== null)) {
      weeks.pop();
    }
    return weeks;
  };

  const calendarData = generateCalendarData(currentYear, currentMonth);
  const weeks = createWeeks(calendarData);

  const toggleWeek = (weekIndex) => {
    setExpandedWeeks((prevState) => ({
      ...prevState,
      [weekIndex]: !prevState[weekIndex],
    }));
  };

  const columns = [
    {
      title: "Employee",
      dataIndex: "employeeName",
      key: "employeeName",
      fixed: "left",
      change: true,
      width: 200,
    },
    {
      title: "Billable",
      dataIndex: "isBillable",
      key: "isBillable",
      fixed: "left",
      width: 100,
      render: (text, record) => {
        return !text ? (
          <RxCross1
            style={{ margin: "0 0 0 1rem", color: "df0b2e", fontSize: "16px" }}
          />
        ) : (
          <IoCheckmarkOutline
            style={{ margin: "0 0 0 1rem", color: "green", fontSize: "18px" }}
          />
        );
      },
    },

    ...weeks.map((week, weekIndex) => {
      const isExpanded = expandedWeeks[weekIndex];
      return {
        title: (
          <div
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => toggleWeek(weekIndex)}
          >
            Week {weekIndex + 1} {isExpanded ? "↑" : "↓"}
          </div>
        ),
        width: 100,
        children: isExpanded
          ? week
              .filter((item) => item !== null)
              .map((item, dayIndex) => {
                return {
                  title: (
                    <div style={{ textAlign: "center" }}>
                      <div>{item.day}</div>
                      <div style={{ fontSize: "14px" }}>
                        {item.date} {monthArray[currentMonth-1].slice(0, 3)}
                      </div>
                    </div>
                  ),
                  dataIndex: `day${item.date}`,
                  key: item.date,
                  width: 80,
                  change: false,
                  render: (text, record) => {
                    if (!record.logs[item.date - 1]) return null; // Handle cases where no logs are present

                    const minutesWorked = record.logs[item.date - 1]?.minutes;
                    const description = record.logs[item.date - 1];
                    const employeeName = record.employeeName;
                    const isOnLeave = record.logs[item.date - 1]?.isOnLeave;
                    const leaveType = record.logs[item.date - 1]?.leaveType;
                    const Date = moment({
                      year: currentYear,
                      month: currentMonth-1,
                      date: item.date,
                    }).format("DD-MM-YYYY");
                    return {
                      children: (
                        <div
                          onClick={() => {
                            if (!item.isWeekend) {
                              setSelectedDay({
                                ...item,
                                month: monthArray[currentMonth-1].slice(0, 3),
                                // hoursWorked,
                                minutesWorked,
                                description: description,
                                employeeName,
                                Date,
                              });
                              setIsModalVisible(true);
                            }
                          }}
                          style={{
                            cursor: item.isWeekend ? "default" : "pointer",
                            height: 55,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color:
                              minutesWorked / 60 >= 8
                                ? "#06c974"
                                : leaveType === 1 || leaveType === 3
                                ? "#df0b2e"
                                : "#000000",
                          }}
                        >
                          {isOnLeave
                            ? leaveType === 1
                              ? "Leave"
                              : leaveType === 3
                              ? "Holiday"
                              : convertTime(minutesWorked)
                            : convertTime(minutesWorked)}
                        </div>
                      ),
                      props: {
                        style: {
                          background: item.isWeekend ? "pink" : "white",
                          padding: 0,
                        },
                      },
                    };
                  },
                };
              })
          : [],
      };
    }),
    {
      title: "Total Time",
      key: "totalHours",
      fixed: "right",
      align:"center",
      width: 120,
      render: (text, record) => {
        const totalMinutes = record.logs.reduce(
          (acc, curr) => acc + curr.minutes,
          0
        );
        return (
          <b>
              {convertTime(totalMinutes)}
          </b>
        );
      },
    },
  ];
  
  const projectView = async () => {
    try {
      setLoading(true);
      const response = await adminProjectView.getProjectView({
        projectId: project,
        month: currentMonth,
        year: currentYear,
      });
      setProjectData(response.employees);
    } catch (error) {
      console.log("project view error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (project && (currentMonth|| currentMonth===0) && currentYear) {
      setProjectData([]);
      projectView();
    }
    else{
      setProjectData([])
    }
  }, [currentMonth, project, currentYear]);

  let totalWorkingHours = 0;
  let totalWorkingMinutes = 0;
  projectData.forEach((employee) => {
    employee.logs?.forEach((data, index) => {
      totalWorkingMinutes += data.minutes;
    });
  });

  totalWorkingHours += Math.floor(totalWorkingMinutes / 60);
  totalWorkingMinutes = totalWorkingMinutes % 60;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      
      <TableComponent
        loading={loading}
        rows={project?projectData:[]}
        columns={columns}
        scrollx={1400}
        onChange={handleTableChange}
        summary={true}
        isActivity={true}
        locale={{ emptyText: "No data available for given input" }}
      />
      <Modal
        title={
          <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
            {`Task Details ${selectedDay?.Date}`}
          </div>
        }
        visible={isModalVisible}
        footer={null}
        width={550}
        onCancel={() => setIsModalVisible(false)}
      >
        <EmployeeViewForm hide={setIsModalVisible} selectedDay={selectedDay} />
      </Modal>
    </div>
  );
};

export default ProjectViewEmp
