import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { convertTime } from "./dateFormat";

const TableComponent = ({
  columns,
  rows = [],
  loading,
  scrollx,
  scrolly,
  pagination,
  onChange,
  summary = false,
  isActivity = false,
  locale,
  totalUtilization,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
    confirm();
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          defaultValue={searchText}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            // type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            // type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      );
    },
  });

  const newCol = columns.map((element) => {
    if (element.dataIndex === "projectOrActivity" && element.change) {
      return {
        ...element,
        sorter: (a, b) => {
          const valA = a[element.dataIndex];
          const valB = b[element.dataIndex];

          if (valA < valB) {
            return -1;
          }
          if (valA > valB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ["ascend", "descend"],
        ...getColumnSearchProps(element.dataIndex, element.title),
        render: (text, record) => {
          const color = {
            color: record?.isInternal
              ? "#009355"
              : record?.type === "activity"
              ? "#FF4D00"
              : "#15A7DF",
          };
          return (
            <span style={color}>
              {searchedColumn === element.dataIndex ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={text ? text.toString() : ""}
                />
              ) : (
                text
              )}
            </span>
          );
        },
      };
    }
    if (element.change) {
      return {
        ...element,
        sorter: (a, b) => {
          const valA = a[element.dataIndex];
          const valB = b[element.dataIndex];

          if (valA < valB) {
            return -1;
          }
          if (valA > valB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ["ascend", "descend"],
        ...getColumnSearchProps(element.dataIndex, element.title),
      };
    }

    return element;
  });

  const totalHours = summary
    ? columns.map((col, colIndex) => {
        if (colIndex === 0) return "Total Time";
        if (colIndex === 1 && isActivity) return "";
        return (
          rows &&
          rows.reduce((acc, curr) => {
            if (curr.logs && isActivity) {
              return acc + (curr.logs[colIndex - 2]?.minutes || 0);
            }
            if (curr.logs) {
              return acc + (curr.logs[colIndex - 1]?.minutes || 0);
            }
          }, 0)
        );
      })
    : Array.from(columns.length).fill(0);

  const totalAllHours = summary
    ? totalHours.slice(isActivity ? 2 : 1).reduce((acc, curr) => acc + curr, 0)
    : 0;

  if (summary) {
    totalHours[totalHours.length - 1] = totalAllHours;
  }

  return (
    <Table
      columns={newCol}
      dataSource={
        rows.length && rows?.map((row, index) => ({ ...row, key: index }))
      }
      loading={loading}
      scroll={{ x: scrollx, y: scrolly }}
      pagination={pagination}
      onChange={onChange}
      locale={locale}
      summary={
        summary
          ? () => (
              <Table.Summary.Row>
                
              </Table.Summary.Row>
            )
          : () => {}
      }
      bordered
      
    />
  );
};

export default TableComponent;
